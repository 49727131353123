<template>
  <vx-card no-shadow>
    <form>
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-input label="Title" placeholder="title" name="title" v-model="data.title" class="w-full" />
          <span class="text-danger text-sm"  v-show="errors.has('title')">{{ errors.first('title') }}</span>
        </div>
        <div class="vx-col w-full mt-5">
            <label class="text-sm">Meta Keywords</label>
            <vs-textarea class="w-full" name="keywords" v-validate="'max:1000'" counter="1000" label="Write keywords" rows="1" :counter-danger.sync="counterDanger" v-model="data.meta_keys" />
            <span class="text-danger text-sm"  v-show="errors.has('keywords')">{{ errors.first('keywords') }}
              </span>
        </div>
        <div class="vx-col w-full mt-4">
          <label class="text-sm">Meta Descriptions</label>
          <vs-textarea class="w-full" name="meta-desc" v-validate="'max:1000'" counter="1000" label="Write meta descriptions" rows="2" :counter-danger.sync="counterDanger" v-model="data.meta_desc" />
            <span class="text-danger text-sm"  v-show="errors.has('meta-desc')">{{ errors.first('meta-desc') }}
            </span>
        </div>
        <div class="vx-col w-full mt-4">
          <label class="text-sm">Descriptions (Separate with '|')</label>
          <vs-textarea class="w-full increase-vs-textarea-font" name="description" label="Write Descriptions" rows="6" v-model="data.description" />
            <span class="text-danger text-sm"  v-show="errors.has('description')">{{ errors.first('description') }}
            </span>
        </div>
      </div>
      <!-- Save & Reset Button -->
      <div class="flex flex-wrap items-center justify-end">
        <vs-button type="filled" size="small" @click.prevent="updateCategory" class="mt-2 block">Update</vs-button>
        <vs-button class="ml-4 mt-2" size="small" type="border" @click="fetchCategory()" color="success">Reset Data</vs-button>
      </div>
    </form>
  </vx-card>
</template>

<script>

export default {
  data() {
    return {
      data: {},
      counterDanger: false,
    }
  },
  methods: {
    updateCategory() {
      this.$validator.validateAll().then(result => {
        if (result && this.$route.params.categoryId > 0) {
          this.$vs.loading({color: "#444", type: "sound"});
          const formData = new FormData()
          formData.append('data', JSON.stringify(this.data))
          this.$http.post(`/categories/${this.$route.params.categoryId}/update-metas`,formData)
          .then((response)=>{
            this.$vs.loading.close()
            if(response.data.success){
              this.$store.commit("categoryList/UPDATE_DESCRIPTION", response.data.data.description)
            }
          })
        }
      })
    },
    patchCategory(){
      let loadedCategory = this.$store.state.categoryList.category.description
      if(loadedCategory){
        this.data = {
          description: loadedCategory.description,
          title: loadedCategory.title,
          meta_keys: loadedCategory.meta_keys,
          meta_desc: loadedCategory.meta_desc,
        }
      }
    }
  },
  created(){
    this.patchCategory()
  }
}
</script>
<style>
.increase-vs-textarea-font .vs-textarea{
  font-size: 18px;
}
</style>
