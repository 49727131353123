
<template>
  <div>
    <div class="">
        <div class="relative h-auto rounded-lg" >
          <div class="vx-row mt-8">
            <div class="vx-col mb-3 items-center flex w-full">
              <vs-button @click="popupUploadBanner = true"
               radius color="warning" type="gradient"
               class="mr-2"
                icon-pack="feather" icon="icon-camera"></vs-button>
              <span> Update Banner </span>
            </div>
            <div class="vx-col w-full">
              <a v-if="bannerImage" target="_blank" :href="bannerImage">{{bannerImage}}</a>
              <i v-else>no file to show</i>
            </div>
          </div>
        </div>
    </div>
    <vs-popup class="holamundo" title="Upload Banner" :active.sync="popupUploadBanner">
        <div>
            <vue-dropzone id="dropzone" ref="catBannerfileUploadDrop" 
              :maxFiles="1" :use-custom-slot="true" :maxFilesize="200" acceptedFiles="image/*,.webp">
              <div class="needsclick m-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload-cloud"><polyline points="16 16 12 12 8 16"></polyline><line x1="12" y1="12" x2="12" y2="21"></line><path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path><polyline points="16 16 12 12 8 16"></polyline></svg>
                <h4>Upload File or Drop file</h4>
                <span class="text-primary font-13">
                    <em>Only one file</em>
                </span>
              </div>
            </vue-dropzone>
        </div>
        <div class="flex flex-wrap justify-end items-center mt-5">
            <vs-button type="filled" @click.prevent="uploadFile()" class="mr-6">Upload</vs-button>
        </div>
    </vs-popup>
</div>
</template>

<script>
import VueDropzone from "@/components/VueDropzone.vue";

export default {
  components:{
    VueDropzone
  },
  data() {
    return {
      popupUploadBanner:false,
    }
  },
  computed:{
    bannerImage(){
      let bn = this.$store.state.categoryList.category.banner;
      return bn
              ? this.absoluteUrl(bn)
              : null

    }
  },
  methods: {
    uploadFile() {
        if(!this.collectFiles().length){ this.alertError("Please an image file is required"); return;}
        const formData = new FormData()
        formData.append('banner', this.collectFiles()[0])
        this.$vs.loading({color: "#444", type: "sound"})
        this.$http.post(`/categories/${this.$route.params.categoryId}/update-banner`,formData)
          .then((response)=> {
          this.$vs.loading.close()
          if(response.data.success){
            this.removeFiles()
            this.$store.commit("categoryList/SET_CATEGORY", response.data.data.category)
          }
      })
    },
    collectFiles(){
      var arrafile = [];
      if(this.$refs.catBannerfileUploadDrop){
        arrafile = [...this.$refs.catBannerfileUploadDrop.getAcceptedFiles()];
      }
      return arrafile
    },
    removeFiles() {
      this.$refs.catBannerfileUploadDrop.removeAllFiles();
      this.popupUploadBanner = false
    },
    close(){
      this.$vs.notify({
        color:'danger',
        title:'Closed',
        text:'You close a dialog!'
      });

    },
  }
}

</script>

