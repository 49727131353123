
<template>
    <div id="faq-page">
      <vs-alert color="danger" title="Category Not Found" :active.sync="category_not_found">
        <span>Category record with id: {{ $route.params.categoryId }} not found. </span>
        <span>
          <span>Check </span><span @click="$router.push({path:'/categories'})" class="text-inherit underline">All Categories</span>
        </span>
      </vs-alert>
      <div v-if="!isLoading">
        <div class="vx-row">
            <div class="vx-col w-full mt-2 md:mt-4">
                <template lang="html">
                  <vs-tabs>
                    <vs-tab label="Overview" icon-pack="feather" icon="icon-home">
                        <category-overview />
                    </vs-tab>
                    <vs-tab label="Products" icon-pack="feather" icon="icon-list">
                        <category-products />
                    </vs-tab>
                    <vs-tab label="Meta & Desc" icon-pack="feather" icon="icon-settings">
                        <category-meta-desc />
                    </vs-tab>
                  </vs-tabs>
                </template>
            </div>
        </div>
      </div>
    </div>
</template>


<script>
import CategoryOverview from "./CategoryOverview.vue"
import CategoryProducts from "./CategoryProducts.vue"
import CategoryMetaDesc from "./CategoryMetaDesc.vue"

export default{
  components: {
    CategoryOverview,
    CategoryProducts,
    CategoryMetaDesc,
  },
  data() {
    return {
      isLoading: true,
      category_not_found: false,
    }
  },
  created(){
    this.$store.dispatch("categoryList/fetchCategory", this.$route.params.categoryId)
    .then((response) => {
     if(response.data.success){
        this.isLoading = false
      }else{
        this.category_not_found = true
      }
    })
  },
}
</script>

<style lang="scss">
#faq-page {
    .faq-jumbotron-content {
        background-size:100%;
        background-repeat-y: no-repeat;
    }
    .faq-bg {
        background-color: #fff;
    }
}
</style>
